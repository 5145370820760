
import { defineComponent } from 'vue'
import { ImagePreview } from 'vant'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Course',
  setup() {
    const route = useRoute()
    // console.log(route.query)
    const { video = '' } = route.query

    const baseURL = process.env.VUE_APP_STATIC_OSS
    const linkImg = `${baseURL}/image/wechat/link-demo.jpg?x-oss-process=image/resize,l_500`
    // const linkVideo = `${baseURL}/other/other/file/video/link-demo-video2.mp4`
    const linkVideo = `${baseURL}/other/other/file/video/${video}.mp4`
    // console.log('linkVideo', linkVideo)
    function preview() {
      ImagePreview([linkImg])
    }

    return { linkImg, preview, linkVideo }
  }
})
