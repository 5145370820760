import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dc04863"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  style: {"width":"100%"},
  height: "440",
  controls: "",
  type: "video/mp4"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", _hoisted_2, [
      _createElementVNode("source", {
        src: _ctx.linkVideo,
        type: "video/mp4"
      }, null, 8, _hoisted_3),
      _createTextVNode(" 您的浏览器不支持Video标签。 ")
    ])
  ]))
}